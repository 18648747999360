.legend {
  display: inline-block;
  width: .8rem;
  height: .8rem;
}

.nav-pills .nav-link.active {
  background-color: rgb(33, 37, 41);
}

.nav-pills .nav-link {
  color: rgb(33, 37, 41);
}

#visitor-count {
  font-size: 6rem;
  justify-content: center;
  text-align: center;
}

.dashboard-container {
  max-height: 70vh;
}