.calculator {
  width: 280px;
}

.display-area {
  height: 80px;
  background-color: rgb(161, 161, 161);
  border-radius: 5px 5px 0 0;
}

.calc-btns>button {
  width: 70px;
  height: 50px;
  border: .5px solid rgb(161, 161, 161);
}

#calc-0 {
  width: 140px;
  height: 50px;
}
