

.section-icon {
  font-size: 4rem;
}

/* unvisited link */
a.plain-link:link {
  color: inherit;

}
/* visited link */
a.plain-link:visited {
  color: inherit;
}
/* mouse over link */
a.plain-link:hover {
  color: #555749;
}
/* selected link */
a.plain-link:active {
  color: blue;
}


