@font-face {
  font-family: 'OSS-Semibold';
  src: url('../public/fonts/OpenSauceSans-SemiBold.ttf');
}

@font-face {
  font-family: 'OSS-Bold';
  src: url('../public/fonts/OpenSauceSans-Bold.ttf');
}

body {
  min-width: 375px;
}

main {
  min-height: 70vh;

}

.nb-card {
  background-color: white;
  border: 2px solid black;
  border-radius: 5px;
  /* x | y | blur| spread | color */
  box-shadow: 9px 9px #272727;
  padding: 30px 40px;
}

.nav-link.active {
  font-weight: bold;
  color: black;
}

.email-btn {
  font-size: 1.5rem;
}

a.navigation-link {
  text-decoration: none;
}

/* unvisited link */
a.navigation-link:link {
  color: inherit;

}

/* visited link */
a.navigation-link:visited {
  color: inherit;
}

/* mouse over link */
a.navigation-link:hover {
  color: inherit;
  background-color: #dcdde1;
}

/* selected link */
a.navigation-link:active {
  color: #555749;
}

.locale {
  font-size: 1rem;
}

.transition-color {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 1000ms;
}

@keyframes slide-in-bottom {
  from {
    transform: translatey(10%);
    opacity: 0;
  }
  to {
    transform: translateX(0%);
    opacity: 100%;
  }
}

.enter-from-below {
  animation: slide-in-bottom 200ms;
}

@keyframes fade-in-place {
  from {
    opacity: 0;
  }
  to {
    opacity: 100%;
  }
}

.fade-in {
  animation: fade-in-place 200ms;
}

.fade-top-and-bottom {
  -webkit-mask-image: linear-gradient(
    to top,
    transparent 0%,
    black 7%,
    black 93%,
    transparent 100%
  );
  mask-image: linear-gradient(
    to top,
    transparent 0%,
    black 10%,
    black 90%,
    transparent 100%
  );
}

.scroll-container {
  max-height: 100%;
  overflow-y: auto;
  /* Hide the scrollbar when there is no overflow */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}