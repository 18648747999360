/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .Cell {
    height: 70px;
    width: 70px;
    background-color: #303952;
    cursor: pointer;
    border: 4px solid #212121;
  }

  .Cell-lit {
    background-color: #f7d794;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .Cell {
    height: 100px;
    width: 100px;
    background-color: #303952;
    cursor: pointer;
    border: 4px solid #212121;
  }

  .Cell-lit {
    background-color: #f7d794;
  }
}
