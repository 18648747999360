#whittle-container {
  max-width: 700px;
}
#title {
  text-align: center;
  height: fit-content;
  background-color: #fff;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

#title h1 {
  font-size: 3rem;
  margin: auto;
  color: #212121;
  border-bottom: .25rem solid #212121;
}

#title p {
  font-size: small;
  color: rgba(102, 102, 102, 0.63);
  text-align: center;
}

input.whittle-cell {
  height: 3rem;
  width: 3rem;
  font-size: 2rem;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  text-align: center;
  caret-color: transparent;
  cursor: pointer;
  text-transform: uppercase;
  margin: 2px;
  padding: 2px;
}

.whittle {
  border: none;
  height: 270px;
  width: 270px;
  margin: auto;
}

.whittle-td {
  border: none;
}

input.whittle-cell.green {
  background-color: #6ab04c;
}

input.whittle-cell.yellow {
  background-color: #f9ca24;
}

input.whittle-cell.grey {
  background-color: #95afc0;
}

#whittle-list {
    grid-column: 1 / 11;
    grid-row: 6;
}