/* #home-container {
  height: 50vw;
} */

.wrapper {
  width: 1000px;
  margin: 100px auto;
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  gap: 50px;
  grid-template-rows: repeat(50px, 1fr);
}


.hero {
  grid-column: 1 / 6;
  grid-row: 1 / 3;
  margin-bottom: 50px;
  text-justify: center;
}

.hero>h1 {
  font-family: 'OSS-Bold', Verdana, Tahoma, sans-serif;
  font-size: 3.5rem;
  font-weight: 900;
}

.geometry {
  grid-column: 6 / 9;
  grid-row: 1 / 3;
}

.about {
  grid-column: 1 / 3;
  grid-row: 3
}

.portfolio {
  grid-column: 3 / 5;
  grid-row: 3
}

.projects {
  grid-column: 5 / 7;
  grid-row: 3
}

.blog {
  grid-column: 7 / 9;
  grid-row: 3
}

@media screen and (max-width: 700px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    width: 90%;
    min-width: 340px;
    margin-top: 2rem;
  }

  .hero {
    margin: 0;
  }
  .hero>h2 {
    font-size: 1.3rem;
  }

  .geometry {
    display: none;
  }

  /* .wrapper {
    grid-template-columns: 1fr;
    width: 100vw;
    align-content: center;
    justify-content: center;
  }
  .hero {
    grid-column: 1;
    grid-row: 1 / 3;
    width: 95%;
  }
  .geometry {
    grid-column: 1;
    grid-row: 3 / 5;
  }
  .about {
    grid-column: 1;
    grid-row: 5
  }
  .portfolio {
    grid-column: 1;
    grid-row: 6
  }
  .projects {
    grid-column: 1;
    grid-row: 7
  }
  .blog {
    grid-column: 1;
    grid-row: 8
  } */
}

#home-animation {
  align-items: center;
  justify-content: center;
}

.home-btns:hover {
  transform: translate(6px, 6px);
  box-shadow: 2px 2px 0 0 #272727;
  /* animation-play-state: running; */
}

.home-btns:active {
  transform: translate(8px, 10px);
  box-shadow: -1px -1px 0 0 #272727;
  background-color: rgb(195, 195, 195);
  /* animation-play-state: paused; */
}

.home-btns {
  font-size: 1.5rem;
  width: 100%;
  font-family: 'OSS-SemiBold', Arial, Helvetica, sans-serif;
  font-size: 21px;
  background-color: white;
  border: 3px solid black;
  border-radius: 10px;
  box-shadow: 8px 8px 0 0 #272727;
  height: 4.5rem;
  cursor: pointer;
  will-change: transform;
  transition: all 0.2s ease;
}