/* Blog List & Summary */
.tags {
  text-decoration: none;
}

li>div>div.card-body {
  border: transparent 4px solid;
  border-radius: 2px;
}

li>div>div.card-body:hover {
  background-color: #d1d8e0;
  border-left: #212121 4px solid;
  border-right: #212121 4px solid;
}

div.card.blog-summary {
  border: none;
}

/* Return Btn */

i.search-icon {
  margin: 0;
}

.code>p {
  text-align: end;
  font-size: smaller;
  color: rgba(30, 30, 30, 0.847);
}

.code {
  font-family: "SFMono-Regular", Menlo, Consolas, "PT Mono", "Liberation Mono", Courier, monospace;
  background: rgba(162, 158, 146, 0.478);
  border-radius: 3px;
  padding: 0.2em 0.4em;
  border-radius: 3px;
  tab-size: 2;
  padding: 1em 1em;
}

.code-wrap {
  white-space: pre-wrap;
  word-break: break-all;
}

.code>code {
  background: none;
  padding: 0;
  color: inherit;
  color: #eb5757;
}

.not-found{
  padding: 2rem;
}
